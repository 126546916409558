import React, { useState } from 'react';
import './Style/ProductTiles.css';

const ProductTiles = () => {
    const products = [
        { src: require('./Attributes/Images/drink.jpg'), title: "Drinks" },
        { src: require('./Attributes/Images/beer.jpg'), title: "Beers" },
        { src: require('./Attributes/Images/wine.jpg'), title: "Wine" },
        { src: require('./Attributes/Images/cigarette.jpg'), title: "Cigarettes" },
        { src: require('./Attributes/Images/vape.jpg'), title: "Vapes" },
        { src: require('./Attributes/Images/candy.jpg'), title: "Candies" },
        { src: require('./Attributes/Images/snack.jpg'), title: "Chips&Snack" }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? products.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === products.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="Tiles">
            <h1>Products</h1>
            <button className="arrow left" onClick={handlePrev}>&#9664;</button>
            <div className="TilesWrapper">
                {products.map((item, index) => (
                    <div
                        className="Tile"
                        key={index}
                        style={{ display: index === currentIndex ? 'block' : 'none' }}
                    >
                        <img src={item.src} alt={`Tile ${index + 1}`} />
                        <div className="TileTextWrapper">
                            <h2>{item.title}</h2>
                        </div>
                    </div>
                ))}
            </div>
            <button className="arrow right" onClick={handleNext}>&#9654;</button>
        </div>
    );
};

export default ProductTiles;
