import {useState, useEffect} from 'react';
import './Style/NavBar.css';
import {Link, useLocation} from 'react-router-dom';
const NavBar = () =>{
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isSticky, setIsSticky] = useState(false);

    const logoSrc = require('./Attributes/Images/RD Mart-logos_black.png');
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavBar = () => {
        setIsOpen(!isOpen);
        console.log("Button Clicked");
    };

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };

        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll',handleScroll);
    
        return () => {
          window.removeEventListener('resize', handleResize);
          window.addEventListener('scroll',handleScroll);
        };
      }, []);

    const getCurrentPageName = () => {
        const path = location.pathname;
        switch (path) {
            case '/Services':
                return 'Services';
            default:
                return 'Home';
        }
    };

    return(
        <div className={`NavBar ${isSticky ? 'sticky' : ''}`}>
            <div className="LogoNavBar">
                <Link to='/'><img src={logoSrc} alt="Store Logo"/></Link>
                <div className="LogoTextContainer">
                <p>{getCurrentPageName()}</p>
                </div>
            </div>
            
                {windowWidth <= 850 ? (
                <nav>
                <div className={`NavBarMenu ${isOpen ? 'active':''}`}>
                <ul className="NavBarItems">
                    <li className='NavBarItem'><Link to="/" >Home</Link></li>
                    <li className='NavBarItem'><Link to="/Services">Services</Link></li>
                </ul>
                </div>
                <div className="NavBarToggle" onClick={toggleNavBar}>
                <div className={`HamburgerIcon ${isOpen ? 'open' : ''}`}>
                    <div className="Line"></div>
                    <div className="Line"></div>
                    <div className="Line"></div>
                </div>
                </div>
            </nav>
                ):(
                <nav>
                <ul className="HorizontalNavBar">
                    <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link></li>
                    <li><Link to="/Services" className={location.pathname === '/Services' ? 'active' : ''}>Services</Link></li>
                </ul>
            </nav>
                )}
        </div>
       
    );
}
export default NavBar;