import GoogleMap from './GoogleMap';
import './Style/Footer.css';
const Footer = () => {
    return ( 
        <footer className="Footer">
            <div className="ContactInformation">
                <p>Copyright &copy; 2024 Rythm LLC-All Rights Reserved.</p>
                <address>
                <p><a href="mailto:roshan.dhital@gmail.com" className="ContactLink">Email: roshan.dhital@rdmarts.com</a></p>
                <p><a href="tel:+19036283807" className="ContactLink">Phone: 903-628-3807</a></p>
                </address>
            </div>
            <div className="MapContainer">
                    <GoogleMap/>
                </div>
        </footer>
    );
};
export default Footer;
