import './Style/Jackpot.css';
const Jackpot = () => {
    const lotteryImageList = [require('./Attributes/Images/megamillions.jpg'),require('./Attributes/Images/texasLottery.jpg'),require('./Attributes/Images/powerball.jpg')];

    return(
        <div className="Jackpot">
            <h1>Win Big with us!</h1>
            <div className="JackpotWrapper">
                {lotteryImageList.map((lotteryImage, index)=>(
                    <div className="ImageAndJackpot" key={index}>
                    <img key={index} src={lotteryImage} alt="Lottery Pictures"/>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Jackpot;