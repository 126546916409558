import {Routes, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import Services from './Components/Services/Services';
import ScrollToTop from './Components/Custom Hooks/ScrollToTop';
const App = () => {
  return (
    <div className="App">
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Services" element={<Services />} />
      </Routes>
    </div>
  );
}
export default App;
