import React, { useEffect } from 'react';

const GoogleMap = () => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    try {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        // Map initialization code after script loads
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 33.47249, lng: -94.41448 },
          zoom: 15
        });

        const marker = new window.google.maps.Marker({
          position: { lat: 33.47249, lng: -94.41448 },
          map: map,
          title: 'Store Location',
          clickable: true
        });

        marker.addListener('click', function() {
          window.open(`https://www.google.com/maps/dir/?api=1&destination=${33.47249},${-94.41448}`, '_blank');
        });
      };
    } catch (e) {
      console.error('Google Maps API is not available', e);
    }
  }, [apiKey]);

  return (
    <div id="map" style={{ height: '150px' , border:'5px solid rgba(0, 0, 0, 0.1)'}}></div>
  );
};

export default GoogleMap;
