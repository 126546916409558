import './Style/Promotions.css';
const Promotions = () => {

    const promotionsList = [{
        imgSrc:require('./Attributes/Images/image_fountain.jpg'),
        title:"Fountain Drinks",
        description:"Any size for a $1"
    },
    {
        imgSrc:require('./Attributes/Images/image_coffee.jpeg'),
        title:"Coffee",
        description:"Any size and refill for a $1"
    }];

    return(
        <div className="Promotions">
                <h1>Promotions</h1>
                <div className="ProductsWrapper">
                {promotionsList.map((product, index)=>(
                        <div className="Product" key={index}>
                            <img  src={product.imgSrc} alt="Promotional Product"/>
                            <div className="TextWrapper">
                                <h2>{product.title}</h2>
                                <h3>{product.description}</h3>
                            </div>
                        </div>
                ))}
                 </div>
        </div>
    );

}
export default Promotions;