import './Style/ServiceGrid.css';
const ServiceGrid = () =>{
    const serviceList = [{
        serviceName:"EV Charger",
        imageSRC: require('./Attributes/Images/image_ev.jpg'),
        serviceDescription: "Coming Soon."
    },{
        serviceName:"Fountain Drinks",
        imageSRC: require('./Attributes/Images/image_fountain.jpg'),
        serviceDescription:"Fountain drinks for $1"
    },{
        serviceName:"Coffee&Capucino",
        imageSRC: require('./Attributes/Images/image_coffee.jpeg'),
        serviceDescription:"Coffee and Cappuccino for $1",
    },{
        serviceName:"Vaccum&Air",
        imageSRC: require('./Attributes/Images/image_vaccair.jpg'),
        serviceDescription:"Accepts debit/credit cards.",
        title:"Vaccum&Air",
        price:{
            Vaccum:"$1.50",
            Air:"$1.50",
        }
    }];

    return(
        <div className = "ServiceGrid">
            <h1>Services</h1>
            <div className="ServiceGridContainer">
            {serviceList.map((service,index)=>(
                <div key={index} className = "Service">
                    <h2>{service.serviceName}</h2>
                    <img src={service.imageSRC} alt={`${index+1}`}/>
                    <div className="ServiceTextWrapper">
                        {service.serviceDescription&&(
                            <h3>{service.serviceDescription}</h3>
                        )}
                        {service.price&&(
                            <table>
                                {service.title&&(<thead>
                                    <tr>
                                        <th colSpan="2">{service.title}</th>
                                    </tr>
                                </thead>)}
                                <tbody>
                                {Object.entries(service.price).map(([item, price]) => (
                                    <tr key={item}>
                                        <td>{item}</td>
                                        <td>{price}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}
export default ServiceGrid;