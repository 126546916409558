import './Style/Home.css';
import NavBar from '../Shared/NavBar';
import ImageCarousel from './ImageCarousel';
import Promotions from './Promotions';
import ProductTiles from './ProductTiles';
import Jackpot from './Jackpot';
import OpenHours from './OpenHours';
import Footer from '../Shared/Footer';
const Home = () =>{
    return(
      <div className="Home">
      <NavBar/>
      <ImageCarousel/>
      <Promotions/>
      <ProductTiles/>
      <Jackpot/>
      <OpenHours/>
      <Footer/>
      </div>
      );
}
export default Home;